import React,{useState} from "react"
import { useForm, ValidationError } from "@formspree/react"

function ContactForm() {
  const [state, handleSubmit] = useForm("mleazbvq");
  const [clientName, setclientName] = useState("");
  const [clientEmailId, setclientEmailId] = useState("")
  if (state.succeeded) {
    return( <div className="alert">
    <p>Thank you {clientName}.</p>
    <p>Your message and feedback are valuable for me.</p>
    <p>You will soon be receiving an email at <span>{clientEmailId}</span></p>
    <p>Thank You</p>
    <p>Suraj</p>
        
    </div>
    )
  }
  return (
    <form onSubmit={handleSubmit} className="form contact-form">
      <div className="form-row">
        <label htmlFor="client-name">your name</label>
        <input type="text" required name="client-name" id="client-name" onChange={(e)=>{setclientName(e.target.value)}} value={clientName}/>

      </div>
      <div className="form-row">
                <label htmlFor="email">your email</label>
                <input type="email" required name="email" id="email"  onChange={(e)=>{setclientEmailId(e.target.value)}} value={clientEmailId}/>
              </div>
      <ValidationError prefix="Email" field="email" errors={state.errors} className="alert-danger"/>
      <div className="form-row">
                <label htmlFor="message">message</label>
                <textarea required minLength="25" name="message" id="message"></textarea>
              </div>
      <ValidationError prefix="Message" field="message" errors={state.errors} className="alert-danger"/>
      <button type="submit" disabled={state.submitting} className="btn block">
      Message Me
      </button>
    </form>
  )
}
export default ContactForm
